export const backendApiURL = "https://func-devsecopshub-prod.azurewebsites.net/api/"
export const _tenantName = "oktaProd"
export const policyName = "B2C_1A_a0018_signup_signin_rest"
export const _clientID = "0oa9sx5me1zLy7omN417"
export const replyURL = window.location.origin
export const sentryDsn = "https://6be5049d66a3423987d4b6e057d69761@o1382842.ingest.sentry.io/4504321781858304"
export let releaseId = "Hub Prod 1536201"

export const clientID = (function() {
    if(window.location.hostname === "localhost"){
        // prod
        //return "f03a5d3c-e986-499c-9331-9066de54b75e"
        // dev
        return "0oa9pl462iCWijSZT417"
    } else {
        return _clientID
    }
}())

export const tenantName = (function() {
    if(window.location.hostname === "localhost"){
        // prod
        //return "draegerb2c"
        // dev
        return "dev."
    } else {
        // @ts-ignore
        if(_tenantName === "oktaDev") {
            return "dev."
        } else {
            return ""
        }
    }
}())

export let getReleaseIdSentry = (function() {
    if (releaseId.indexOf("RELEASEID") !== -1 || releaseId.length === 0) {
        return "DevSecOps Hub Dev"
    }
    return releaseId
}())

export const ENVIRONMENT = (function() {
    if(window.location.hostname === "localhost" || window.location.origin.includes("stdevsecopshubstage")) {
        return "dev"
    } else {
        return "prod"
    }
}())
