import * as React from "react";
import {ReactElement, useEffect} from "react";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {
    filterState,
    IApiKey,
    IApiKeyContentTypes,
    Popup,
    SearchByApiKeys
} from "../Interfaces/IApiKeyManagement";
import {deleteApiKeys, getApiKeys} from "../BackendFacade/apiCalls";
import {Button, Form, FormControl, Modal, Table} from "react-bootstrap";
import styles from "../Hub.module.css";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {IconDelete, IconEdit} from "../../../Assets/svgs";
import {deepCopy, formatDate, replaceProjectGuidWithDisplayName} from "../../../Utils/transformer";
import {
    convertAuthlevelToTenantsystem,
    GlobalTenantSystemContent, TenantSystemContent,
} from "../../IDMS/Utils/tenantSystem";
import ApiKeyField from "../Components/ApiKeyField";
import {IApiKeysAddEditState, IApiKeysEditMode} from "../Interfaces/IApiKeysAddEditState";
import ApiKeys_AddEditModal from "./Modals/ApiKeys_AddEdit";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const ApiKeyManagement: React.FC = () => {
    // get project id if called from within project, "null" if called from within hub
    let projectId = "null"
    if (HubPathRouting.currentProject) {
        projectId = HubPathRouting.currentProject.moduleInstanceId
    }

    // load all module instances for scope replacement
    GlobalDictionary.getProjects()
    
    const [apiKeys, setApiKeys] = React.useState<IApiKey[]>([])
    const [sortKey, setSortKey] = React.useState<string>("displayName")
    const [sortReverse, setSortReverse] = React.useState<boolean>(false)
    const [filterBy, setFilterBy] = React.useState<SearchByApiKeys>(SearchByApiKeys.Include)
    const [filterState, setFilterState] = React.useState<filterState>({} as filterState)
    const [filterValueState, setFilterValueState] = React.useState<filterState>({} as filterState)
    const [selectedApiKey, setSelectedApiKey] = React.useState<IApiKey | undefined>(undefined)
    const [popup, setPopup] = React.useState<Popup>(Popup.None)
    const [currentProject, setCurrentProject] = React.useState<string>(projectId)
    const [showKey1, setShowKey1] = React.useState<boolean>(false)
    const [showKey2, setShowKey2] = React.useState<boolean>(false)
    const [addEditModal, setAddEditModal] = React.useState<IApiKeysAddEditState | undefined>(undefined)

    const {popupCreate, setLoading, popupCreateSingle} = useMain();


    useEffect(() => {
        if(!GlobalDictionary.get("apiKeys" + currentProject)) {
            getApiKeysWait()
        } else {
            getBackendApiKeys()
        }
    }, [])

    const getApiKeysWait = async () => {
        setLoading(true);
        await getBackendApiKeys()
    }

    /**
     * Get the config data from the backend
     */
    const getBackendApiKeys = async() => {
        getApiKeys(currentProject).then((data) => {
            if(data.success) {
                GlobalDictionary.set("apiKeys" + currentProject, data.data.value)
                // setState({apiKeys: data.data.value as IApiKey[]})
                setApiKeys(data.data.value as IApiKey[])
            } else {
                popupCreate("Error", "Error getting api Keys: " + data.message)
            }
            setLoading(false)
        })
    }

    const setSortByKey = (key: string) => {
        // setState({Sort_Reverse: !Sort_Reverse, sortKey: key})
        setSortReverse(!sortReverse)
        setSortKey(key)
    }

    const showSortIcon = (key: string)  => {
        if(key === sortKey) {
            return sortReverse ? <i className="uib-icon--arrow1-down uib-icon"></i> : <i className="uib-icon--arrow1-up uib-icon"></i>
        }
    }

    const sort = () => {
        return sortListByKey(apiKeys, sortKey, sortReverse)
    }

    /**
     * Displays the details of the tenant config
     * @param key
     * @constructor
     */
    const Details_show = (key: IApiKey) => {
        let content : ReactElement
        if(key.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
            const authLevel = JSON.parse(key.content)
            const tenantSystem = convertAuthlevelToTenantsystem(authLevel, currentProject)
            let globalId = tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
                (value, index) => value === ["user"][index]
            ))?.id || 0
            content = <>
                <GlobalTenantSystemContent tenantSystem={tenantSystem} globalId={globalId} />
                <TenantSystemContent tenantSystem={tenantSystem} global={true} />
            </>
        } else {
            content = <FormControl disabled
                                   id={"content"}
                                   className = {styles.ApiKeyContentUndefined}
                                   value = {key.content} ></FormControl>
        }

        const popupContent = <div>
            <div id={"contentType"} className={styles.ApiKeyContentType}>Content Type: {key.contentType}</div>
            <div id={"content"}>{content}</div>
            <div className={styles.ApiKeyHeader}>Key 1</div>
            <div>
                <ApiKeyField keyString={key.primaryApiKey} />
            </div>
            <div className={styles.ApiKeyHeader}>Key 2</div>
            <div>
                <ApiKeyField keyString={key.secondaryApiKey} />
            </div>
        </div>

        popupCreateSingle(`Api Key ${key.displayName} Details`, popupContent)
    }

    /**
     * sort the users list by clicking on the column header
     * @param list
     * @param key
     * @param reverse
     */
    const sortListByKey  = (list: IApiKey[], key: string, reverse: boolean = false) : IApiKey[] => {
        if(list.length === 0) {
            return []
        }
        let apiKeysCopy = deepCopy(list);

        // apply filters
        //listofKeys = applyFilter(list, listofKeys)
        // if first item of list is a number
        switch(key) {
            case "lastLogin":
                apiKeysCopy.sort((a: any, b: any) => {
                    // if lastlogin is undefined, sort it to the end
                    if(list[a].lastUsedOn === undefined) {
                        return 1
                    }
                    if(list[b].lastUsedOn === undefined) {
                        return -1
                    }
                    if(list[a].lastUsedOn < list[b].lastUsedOn) {
                        return 1;
                    }
                    if(list[a].lastUsedOn > list[b].lastUsedOn ) {
                        return -1;
                    }
                    return 0;
                })
                break;
            default:
                break;
        }
        if(reverse) {
            apiKeysCopy.reverse();
        }
        return apiKeysCopy
    }

    /**
     * prepare user add/edit popup config
     * @param key key of the user, empty for a new user
     */
    const EditEntry_show = (key: IApiKey) => {
        let AddEdit: IApiKeysAddEditState = {
            editMode: undefined,
            tenantSystem: undefined,
            Selected_Value: key,
            listOfSubscopes: [], // TODO: add subscopes
            expirationDate: false,
        }
        // if a new api key shall be created
        if(key === undefined) {
            AddEdit.editMode = IApiKeysEditMode.Add
            AddEdit.Selected_Value = {
                displayName: "",
                secondaryApiKey: "",
                primaryApiKey: "",
                projectId: currentProject,
                apiKeyId: "",
                content: "",
                contentType: IApiKeyContentTypes.hubIdmsTenantJson,
                lastUsedOn: "",
                validUntil: "",
            }
            AddEdit.tenantSystem = [{id: 0, scopes: ["user"], value: "", tmp: "", fullscope: "user"}]
        } else {
            AddEdit.editMode = IApiKeysEditMode.Edit
            AddEdit.Selected_Value = deepCopy(key)

            if(key.validUntil !== null && key.validUntil !== "") {
                AddEdit.expirationDate = true
            }

            // if contentType is from the hub, convert it to the tenant system
            if(AddEdit.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
                const authLevel = JSON.parse(AddEdit.Selected_Value.content)
                AddEdit.tenantSystem = convertAuthlevelToTenantsystem(authLevel, currentProject)
            } else {
                AddEdit.tenantSystem = undefined
            }
        }
        setPopup(Popup.ApiKey_AddEdit)
        setAddEditModal(AddEdit)
    }

    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    const Delete_show = (key: IApiKey) => {
        popupCreate("Delete Api Key", `Do you really want to delete ${key.displayName}?`,
            Delete_apply.bind(this, key))
    }

    /**
     * Confirm api key deletion
     */
    const Delete_apply = (key: IApiKey) => {
        setLoading(true)
        deleteApiKeys(key.projectId, key.apiKeyId).then(async (data) => {
            if(!data.success) {
                setLoading(false)
                popupCreate("Error", data.message)
            } else {
                await getApiKeysWait()
            }
        })
    }

    const closePopup = () => {
        setPopup(Popup.None)
    }

        let popupContent = <div></div>

        switch(popup) {
            case Popup.ApiKey_AddEdit:
                popupContent = <ApiKeys_AddEditModal state={addEditModal}
                                                    
                                                     projectId={currentProject}
                                                     onSuccessFunction={() => {closePopup(); getApiKeysWait()}}
                                                     closeFunction={() => closePopup()}/>
                break;
            default:
                break;
        }
        return <>
            <Modal size="lg" show={popup !== Popup.None} onHide={() => setPopup(Popup.None)} >
                {popupContent}
            </Modal>

            {/*{filterbar()}*/}
            <div className={styles.ScrollPageUsers}>
                <Form>
                    <Table striped className={""} id={"tableOfUsers"}>
                        <thead className={`uib-table__header ${styles.tablehead}`}>
                            <tr className={`uib-table__row--header ${styles.tableHeadRow}`}>
                                <th className={styles.hoverPointer}
                                    onClick={() => setSortByKey("displayName")}>
                                    Display Name{showSortIcon("displayName")}
                                </th>
                                {!HubPathRouting.currentProject &&
                                    <th className={`${styles.hoverPointer}`}
                                        onClick={() => setSortByKey("projectId")}>
                                        Project Id{showSortIcon("projectId")}
                                    </th>
                                }
                                <th className={`${styles.hoverPointer} ${styles.alignCenter}`}
                                    onClick={() => setSortByKey("lastUsedOn")}>
                                    Last Used On{showSortIcon("lastUsedOn")}
                                </th>
                                <th className={`${styles.hoverPointer} ${styles.alignCenter}`}
                                    onClick={() => setSortByKey("validUntil")}>
                                    Valid Until{showSortIcon("validUntil")}
                                </th>
                                <th className={`${styles.hoverPointer} ${styles.alignCenter}`} >
                                    Details
                                </th>
                                <th className={styles.alignCenter}>Edit</th>
                                <th className={styles.alignCenter}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {sort()
                            .map((apiKey, index) => {
                                return <tr key={index}>
                                    <td>{apiKey.displayName}</td>
                                    {!HubPathRouting.currentProject &&
                                        <td>{replaceProjectGuidWithDisplayName(apiKey.projectId)}</td>
                                    }
                                    <td className={styles.alignCenter}>{formatDate(apiKey.lastUsedOn) !== "" ? formatDate(apiKey.lastUsedOn) : "Never"}</td>
                                    <td className={styles.alignCenter}>{formatDate(apiKey.validUntil) !== "" ? formatDate(apiKey.validUntil) : "-"}</td>
                                    <td className={styles.alignCenter}>
                                        <Button variant="outline-primary"
                                                className={styles.details}
                                                id={"details"}
                                                onClick={() => Details_show(apiKey)}>
                                            Details
                                        </Button>
                                    </td>
                                    <td className={styles.alignCenter}>
                                        <Button variant="outline-primary"
                                                className={styles.ButtonLogo}
                                                id={"edit"}
                                                onClick={() => {
                                                    EditEntry_show(apiKey)
                                                }}>
                                            {IconEdit}
                                        </Button>
                                    </td>
                                    <td className={styles.alignCenter}>
                                        <Button variant="outline-danger"
                                                className={styles.ButtonLogo}
                                                id={"delete"}
                                                onClick={() => {
                                                    Delete_show(apiKey)
                                                }}>
                                            {IconDelete}
                                        </Button>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </Form>
            </div>
            <div className={`container ${styles.FooterRoot}`}>
                <div className="row">
                    {isUserroleSufficient(UserRoles.admin, HubPathRouting.currentProject?.moduleInstanceId) &&
                        <div className="col-auto">
                            <Button className="uib-button uib-button--primary"
                                    id={"add"}
                                    onClick={() => EditEntry_show(undefined)}>
                                + Add
                            </Button>
                        </div>}
                    <div className={`col-sm ${styles.FooterUser}`} id={"noOfKeys"}>
                        Api Keys Count: {apiKeys.length}
                    </div>
                </div>
            </div>
        </>
    
}

export default ApiKeyManagement;